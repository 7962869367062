import { useState } from 'react';
import { PageTitle } from 'src/entities/PageTitle/PageTitle';
import { Button } from 'src/shared/ui/_buttons/Button';
import { ModalNewProto } from 'src/shared/ui/_modals/ModalNewProto/ModalNewProto';
import { useGetGroupUserRights } from '../../hooks/useGetGroupUserRights';
import { AddEditGroupMC } from '../_modals/AddEditGroupMC';

const EMPLOYEE_PORTAL_URL = process.env.REACT_APP_EMPLOYEE_PORTAL_URL ?? 'https://lanit-tercom.1c-cabinet.ru/auth/login/password';

export const Header = () => {
	const [isGroupModalOpen, setGroupModalOpen] = useState(false);
	// * Rights
	const { hasExtendedRights } = useGetGroupUserRights();

	const toggleGroupModal = () => {
		setGroupModalOpen(prevState => !prevState);
	};

	const handleOpenEmployeePortal = () => {
		window.open(EMPLOYEE_PORTAL_URL, '_blank', 'noopener,noreferrer');
	};

	const actionButtons = [
		hasExtendedRights && (
			<Button
				key="add-group-button"
				variant="secondary"
				onClick={toggleGroupModal}
			>
				Добавить новую группу
			</Button>
		),
		<Button
			key="employee-portal-button"
			onClick={handleOpenEmployeePortal}
		>
			Личный кабинет 1С
		</Button>,
	];

	// * Render
	return (
		<>
			<ModalNewProto
				isOpen={isGroupModalOpen}
				onClose={toggleGroupModal}
				width="s"
			>
				<AddEditGroupMC closeModal={toggleGroupModal} />
			</ModalNewProto>

			<PageTitle
				title="Календарь отпусков"
				buttons={actionButtons}
			/>
		</>
	);
};
