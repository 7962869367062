import { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useGetGroupsByGroupIdSubgroupsQuery } from 'src/app/redux/queries/vacation-service/vacation_serviceAPI';
import { ReactComponent as SearchSVG } from 'src/shared/assets/svg/action/search.svg';
import { SelectSingle } from 'src/shared/ui/_inputs/selects/SelectSingle';
import { TextInput } from 'src/shared/ui/_inputs/text_Inputs/TextInput';
import { FILTER_PARAMS_NAMES } from '../../consts/consts';
import { useVacationPageContext } from '../../context/useGroupUsersContext';
import { getCategorizedSubgroups } from '../../lib/getCategorizedSubgroups/getCategorizedSubgroups';
import { User } from '../../types';
import { getGroupsDisplay } from '../VacationTable/ui/VacationTable/lib/getGroupsDisplay';
import s from './Filters.module.scss';
import { DEFAULT_OPTION, STATUS_DATA } from './consts/consts';

interface Props {
	users: User[];
	setIsFiltersApplied: Dispatch<SetStateAction<boolean>>;
}

export const Filters: FC<Props> = props => {
	const { users, setIsFiltersApplied } = props;

	const [searchParams, setSearchParams] = useSearchParams();
	const groupId = searchParams.get('groupId') as string;

	// * Context
	const { setFilteredUsers } = useVacationPageContext();

	const { displayCategory, displayGroup, displayTeam } = getGroupsDisplay(users);

	// * API
	const { data: subgroupData } = useGetGroupsByGroupIdSubgroupsQuery(
		{
			groupId,
			skipcount: 0,
			takecount: 1000,
		},
		{
			skip: groupId === null || groupId === 'null',
		},
	);

	const subgroups = subgroupData?.body;

	// * Data for filters
	const { categories, groups, teams } = getCategorizedSubgroups(subgroups);

	// * Filters
	const name = searchParams.get(FILTER_PARAMS_NAMES.name) || '';
	const category = searchParams.get(FILTER_PARAMS_NAMES.category);
	const group = searchParams.get(FILTER_PARAMS_NAMES.group);
	const team = searchParams.get(FILTER_PARAMS_NAMES.team);
	const status = searchParams.get(FILTER_PARAMS_NAMES.status);

	const setValue = (key: string, value: string) => {
		if (value) searchParams.set(key, value);
		else searchParams.delete(key);
		setSearchParams(searchParams);
	};

	// * Filters apply
	useEffect(() => {
		let newUsers = users;

		if (name !== '') newUsers = newUsers.filter(user => user.name.toLocaleLowerCase().includes(name));
		if (category) newUsers = newUsers.filter(user => user.category === category);
		if (group) newUsers = newUsers.filter(user => user.group === group);
		if (team) newUsers = newUsers.filter(user => user.team === team);
		if (status === 'vacationAdded') newUsers = newUsers.filter(user => user.vacations && user.vacations.length > 0);
		if (status === 'vacationNotAdded') newUsers = newUsers.filter(user => !user.vacations || user.vacations.length === 0);

		setFilteredUsers(newUsers);

		const filtersApplied = Boolean(Boolean(name) || category || group || team || status);
		if (filtersApplied) {
			setIsFiltersApplied(filtersApplied);
		}
	}, [name, category, group, team, status]);

	// * Render
	return (
		<div className={s.container}>
			<TextInput
				value={name}
				placeholder="Поиск по ФИО"
				onChange={event => setValue(FILTER_PARAMS_NAMES.name, event.currentTarget.value)}
				RightIcon={<SearchSVG />}
			/>

			<SelectSingle
				placeholder="Статус"
				keyNames={{
					name: 'name',
					value: 'id',
				}}
				options={STATUS_DATA}
				selectedOption={STATUS_DATA.find(item => item.id === status) || STATUS_DATA[0]}
				setSelectedOption={value => setValue(FILTER_PARAMS_NAMES.status, value.id)}
			/>

			{displayCategory && (
				<SelectSingle
					placeholder="Категория"
					keyNames={{
						name: 'name',
						value: 'id',
					}}
					options={categories}
					selectedOption={categories.find(item => item.name === category) || DEFAULT_OPTION}
					setSelectedOption={value => setValue(FILTER_PARAMS_NAMES.category, value.name)}
				/>
			)}

			{displayGroup && (
				<SelectSingle
					placeholder="Группа"
					keyNames={{
						name: 'name',
						value: 'id',
					}}
					options={groups}
					selectedOption={groups.find(item => item.name === group) || DEFAULT_OPTION}
					setSelectedOption={value => setValue(FILTER_PARAMS_NAMES.group, value.name)}
				/>
			)}

			{displayTeam && (
				<SelectSingle
					placeholder="Команда"
					keyNames={{
						name: 'name',
						value: 'id',
					}}
					options={teams}
					selectedOption={teams.find(item => item.name === team) || DEFAULT_OPTION}
					setSelectedOption={value => setValue(FILTER_PARAMS_NAMES.team, value.name)}
				/>
			)}
		</div>
	);
};
