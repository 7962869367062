import { useRef, useState } from 'react';
import { useAppSelector } from 'src/app/redux/utils';
import { LoaderCircle } from 'src/shared/ui/_loaders/LoaderCircle/LoaderCircle';
import { EmptyData } from 'src/shared/ui/EmptyData/EmptyData';
import { useVacationPageContext } from '../../../../context/useGroupUsersContext';
import { CELL_GAP, DIVIDER_WIDTH } from '../../consts';
import { SortByState } from '../../types';
import { MonthSelector } from '../MonthSelector';
import { ScrollbarWrapper } from '../ScrollbarWrapper';
import { Table } from '../Table/ui/Table/Table';
import { UsersTable } from '../UsersTable';
import { getGroupsDisplay } from './lib/getGroupsDisplay';
import { getUserColumns } from './lib/getUserColumns';
import { sortUsers } from './lib/sortUsers';
import { useGetSelectedYearInfo } from './useGetSelectedYear';
import s from './VacationTable.module.scss';

export const VacationTable: React.FC = () => {
	// * Context
	const { users, isLoading, isLoadingSuccess, searchParams } = useVacationPageContext();

	// * Routing
	const year = searchParams.get('year');

	// * Columns
	const groupsDisplay = getGroupsDisplay(users);
	const columns = getUserColumns(groupsDisplay);

	// * Sorting
	const [sortBy, setSortBy] = useState<SortByState>(null);
	const sortedUsers = sortUsers(users, sortBy);

	// * Year info
	const selectedYearInfo = useGetSelectedYearInfo(Number(year));

	// * Table
	const leftSideWidth = columns.reduce((sum, current) => (sum += current.width + CELL_GAP), 0) + DIVIDER_WIDTH; // TODO: Заменить вычисления на реальное количество колонок.
	const tableRef = useRef<HTMLDivElement>(null);

	// * Active user
	const activeUserId = useAppSelector(state => state.user_service.user.userInfo)?.user.id;
	const activeUser = users.find(user => user.id === activeUserId);
	const allUsers = {
		mainUser: activeUser && [activeUser],
		otherUsers: sortedUsers,
	};

	// * Render
	if (isLoading || !selectedYearInfo) {
		return <LoaderCircle />;
	}

	if (isLoadingSuccess && users.length === 0) {
		return <EmptyData />;
	}

	return (
		<div className={s.container}>
			<ScrollbarWrapper
				usersTableWidth={leftSideWidth}
				usersCount={sortedUsers.length + (activeUser ? 1 : 0)}
				ref={tableRef}
				isActiveUser={!!activeUser}
			>
				<div className={s.table}>
					<UsersTable
						users={allUsers}
						columns={columns}
						sortBy={sortBy}
						setSortBy={setSortBy}
					/>

					<Table
						users={allUsers}
						selectedYear={selectedYearInfo}
					/>
				</div>
			</ScrollbarWrapper>

			<MonthSelector
				width={`calc(100% - ${leftSideWidth}rem)`}
				selectedYear={selectedYearInfo}
				tableRef={tableRef}
			/>
		</div>
	);
};
