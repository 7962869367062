import { yupResolver } from '@hookform/resolvers/yup';
import { FC, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { usePostEmailsMutation } from 'src/app/redux/queries/vacation-service/vacation_serviceAPI';
import { useAppDispatch } from 'src/app/redux/utils';
import { UserListItem } from 'src/features/_card_lists/types';
import { actionsNotifications } from 'src/features/notifications/_BLL/slice';
import { useVacationPageContext } from 'src/pages/vacation/ui/VacationPage/context/useGroupUsersContext';
import { ModalNewProto } from 'src/shared/ui/_modals/ModalNewProto/ModalNewProto';
import * as yup from 'yup';
import { Status } from '../../consts';
import { MCType } from '../../types';
import { AddAddresseeMC } from '../AddAddresseeMC';
import { SendEmailMC } from '../SendEmailMC';

interface FormValues {
	theme: string;
	content: string;
}

interface Props {
	modalSendEmail: boolean;
	toggleModalSendEmail: () => void;
}

export const SendMailModal: FC<Props> = props => {
	const {
		modalSendEmail, //
		toggleModalSendEmail,
	} = props;

	// * Actions
	const dispatch = useAppDispatch();
	const { addNotification } = actionsNotifications;

	const [sendEmails, { isLoading }] = usePostEmailsMutation();

	// * Context
	const { users } = useVacationPageContext();

	const allUsers: UserListItem[] = users.map(({ id, name, position, avatar, vacations }) => ({
		id,
		name,
		position,
		avatar,
		status: vacations && vacations.length > 0 ? Status.True : Status.False,
	}));

	const [activeMC, setActiveMC] = useState<MCType>('email');

	const [tempSelectedUserIds, setTempSelectedUserIds] = useState<string[]>([]);
	const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);
	const removeUser = (userId: string) => {
		setTempSelectedUserIds(prevState => prevState.filter(selectedUserId => selectedUserId !== userId));
		setSelectedUserIds(prevState => prevState.filter(selectedUserId => selectedUserId !== userId));
	};

	// *
	const defaultValues = {
		theme: '',
		content: '',
	};

	const schema = yup.object().shape({
		theme: yup.string().trim().required('Укажите тему письма'),
		content: yup.string().trim().required('Письмо не должно быть пустым'),
	});

	const formMethods = useForm({
		defaultValues,
		resolver: yupResolver(schema),
	});

	const { handleSubmit } = formMethods;

	const onSubmit = (values: FormValues) => {
		const { theme, content } = values;

		sendEmails({
			sendEmailRequest: {
				title: theme,
				text: content,
				userIds: selectedUserIds,
			},
		})
			.unwrap()
			.then(() => {
				dispatch(
					addNotification({
						type: 'success',
						message: 'Письма успешно отправлены',
					}),
				);
				toggleModalSendEmail();
			})
			.catch(error => console.log(error));
	};

	const MC = {
		email: (
			<SendEmailMC
				allUsers={allUsers}
				toggleModal={toggleModalSendEmail}
				setActiveMC={setActiveMC}
				selectedUserIds={selectedUserIds}
				removeUser={removeUser}
				isLoading={isLoading}
			/>
		),
		addressee: (
			<AddAddresseeMC
				allUsers={allUsers}
				setActiveMC={setActiveMC}
				tempSelectedUserIds={tempSelectedUserIds}
				setTempSelectedUserIds={setTempSelectedUserIds}
				selectedUserIds={selectedUserIds}
				setSelectedUserIds={setSelectedUserIds}
			/>
		),
	};

	// * Render
	return (
		<ModalNewProto
			isOpen={modalSendEmail}
			onClose={toggleModalSendEmail}
			width="l"
		>
			<FormProvider {...formMethods}>
				<form onSubmit={handleSubmit(onSubmit)}>{MC[activeMC]}</form>
			</FormProvider>
		</ModalNewProto>
	);
};
