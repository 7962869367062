import { ButtonPair } from 'src/entities/_buttons/ButtonPair';
import { UserListItem } from 'src/features/_card_lists/types';
import { AddButton } from 'src/shared/ui/_buttons/AddButton';
import { StringField } from 'src/shared/ui/_fields/StringField';
import { TextAreaField } from 'src/shared/ui/_fields/TextAreaField';
import { Heading } from 'src/shared/ui/Heading';
import { MCType } from '../../types';
import { UsersList } from '../UsersListContainer/UsersList';
import s from './SendEmailMC.module.scss';

interface Props {
	allUsers: UserListItem[];
	toggleModal: () => void;
	setActiveMC: React.Dispatch<React.SetStateAction<MCType>>;
	selectedUserIds: string[];
	removeUser: (userId: string) => void;
	isLoading: boolean;
}

export const SendEmailMC: React.FC<Props> = props => {
	const {
		allUsers, //
		toggleModal,
		setActiveMC,
		selectedUserIds,
		removeUser,
		isLoading,
	} = props;

	const selectedUsers = selectedUserIds.map(userId => allUsers.find(user => user.id === userId)).filter(user => user !== undefined) as UserListItem[];

	const onCancelClick = () => {
		toggleModal();
	};

	const onAddButtonClick = () => {
		setActiveMC('addressee');
	};

	const primaryDisabled = selectedUsers.length === 0;

	// * Render
	return (
		<div>
			<Heading
				level={2}
				marginBottom="m"
			>
				Отправить e-mail
			</Heading>

			<div className={s.form}></div>

			<h4>Письмо</h4>

			<div className={s.fields}>
				<StringField
					name="theme"
					label="Тема письма"
					placeholder="Введите тему письма"
				/>

				<TextAreaField
					name="content"
					label="Текст письма"
					placeholder="Введите текст письма"
					size="small"
				/>
			</div>

			<h4>Адресаты</h4>

			<AddButton
				className={s.addButton}
				title="Добавить адресата"
				onClick={onAddButtonClick}
			/>

			{selectedUsers.length > 0 && (
				<div className={s.users}>
					<UsersList
						users={selectedUsers}
						onRemoveUser={removeUser}
					/>
				</div>
			)}

			<ButtonPair
				className={s.buttons}
				primaryText="Сохранить"
				primaryIsLoading={isLoading}
				primaryDisabled={primaryDisabled}
				secondaryText="Отмена"
				secondaryOnClick={onCancelClick}
				secondaryIsLoading={isLoading}
			/>
		</div>
	);
};
